<template>
	<div :class="classObj" class="app-wrapper">
		<div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
		
		<sidebar class="sidebar-container" />
		<div class="main-container">
			<div class="fixed-header">
				<navbar />
				<tabbar ref="tabbar"/>
			</div>
			
			<mainframe @closeCurrentTags="closeCurrentTags"/>
		</div>
	</div>
</template>

<script>
import {mapState} from 'vuex';
import {Navbar, Sidebar, Tabbar, Mainframe} from './components';
import ResizeMixin from '@/components/mixin/ResizeHandler';
import {getLocalStorage} from '@/system/store/localstorage';

export default{
	name: 'part',
	components: {
		Mainframe,
		Navbar,
		Sidebar,
		Tabbar
	},mixins: [
		ResizeMixin
	],computed: {
		...mapState({
			sidebar: state => state.app.sidebar,
			device: state => state.app.device,
		}),classObj() {
			return {
				hideSidebar: !this.sidebar.opened,
				openSidebar: this.sidebar.opened,
				withoutAnimation: this.sidebar.withoutAnimation,
				mobile: this.device === 'mobile'
			}
		}
	},methods: {
		handleClickOutside() {
			this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
		},closeCurrentTags(){
			this.$refs.tabbar.closeCurrentTags();
		}
	},created: function(){
		var currentLang = (getLocalStorage('currentLang')) ? getLocalStorage('currentLang') : 'en';
		this.$i18n.locale = currentLang;
	}
}

</script>