import {getKey, getIV} from '@/system/library/general';
import CryptoJS from 'crypto-js';

const privateKey = getKey();
const privateIV = getIV();

function getAesString(data, key, iv) {
    var key_hash = CryptoJS.MD5(key).toString();
    var keys = CryptoJS.enc.Utf8.parse(key_hash);  
    var ivs = CryptoJS.enc.Utf8.parse(iv);
    var encrypted = CryptoJS.AES.encrypt(data, keys,
        {
            iv: ivs,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
    return encrypted.toString();
}
function getDAesString(encrypted, key, iv) {
    var key_hash = CryptoJS.MD5(key).toString();
    var keys = CryptoJS.enc.Utf8.parse(key_hash);
    var ivs = CryptoJS.enc.Utf8.parse(iv);
    var decrypted = CryptoJS.AES.decrypt(encrypted, keys,
        {
            iv: ivs,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
    return decrypted.toString(CryptoJS.enc.Utf8);
}

export function getEAES(data){
    var encrypted = getAesString(data, privateKey, privateIV);
    return encrypted;
}

export function getDAES(data){
    return getDAesString(data, privateKey, privateIV);
}
