<template>
	<div>
		<preloader v-if="loading"></preloader>
		<router-view v-if="isRouterAlive"></router-view>
	</div>
</template>

<script>
import preloader from '@/components/preloader';

export default{
	components:{
		preloader,
	},
	provide(){
		return{
			reload:this.reload,
			preloader:this.preloader,
		}
	},
	data(){
		return{
			loading: false,
			isRouterAlive:true,
		}
	},
	methods:{
		preloader(value){
			this.loading = value;
		},reload(){
            this.isRouterAlive = false
            this.$nextTick(function(){
                this.isRouterAlive = true;
            })
        },
	}
}
</script>