<script>
export default {
	name: 'MenuItem',
	functional: true,
	props: {
		icon: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: ''
		}
	},
	render(h, context) {
		const { icon, title } = context.props
		const vnodes = []

		if (icon) {
			const newIcon = 'el-icon-'+icon; 
			vnodes.push(<i class={newIcon}></i>)
		}

		if (title) {
			vnodes.push(<span slot='title'>{(title)}</span>)
		}
		return vnodes
	}
}
</script>
