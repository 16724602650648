import Vue from 'vue';

Vue.prototype.$method = {
    getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
            if (index === 0) {
                sums[index] = this.$t('general.grand_total');
                return;
            }
            const values = data.map(item => Number(item[column.property]));

            if (!values.every(value => isNaN(value))) {
                sums[index] = values.reduce((prev, curr) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    } else {
                        return prev;
                    }
                }, 0);
                sums[index] += '';
            } else {
                sums[index] = '';
            }
        });

        return sums;
    },exportDownload(exportHeader,exportData,exportFilter,downloadLoading,exportName) {
        downloadLoading = true
        var d = new Date();
        var dateTime = d.getFullYear()+""+(d.getMonth()+1)+""+d.getDate()+""+d.getHours()+""+d.getMinutes()+""+d.getSeconds();
        import('@/components/export2excel').then(excel => {
            const data = this.formatJson(exportFilter, exportData)
            excel.export_json_to_excel({
                header: exportHeader,
                data,
                filename: dateTime + exportName
            })
            downloadLoading = false
        })
    },formatJson(exportFilter, exportData) {
        return exportData.map(v => exportFilter.map(j => {
            return v[j]
        }))
    },popupErrorMessage(self,text){
        self.$alert(text, self.$t('msg.msg_error_title'), {
            type: 'error',
            dangerouslyUseHTMLString: true
        });
    },genRandomCode(number){
        let text = "";
        let chars = "abcdefghijklmnopqrstuvwxyz123456789";
        
        for( let i=0; i < number; i++ ) {
            text += chars.charAt(Math.floor(Math.random() * chars.length));
        }
    
        return text.toUpperCase();
    },genRandomSerialCode(number){
        let text = "";
        let chars = "0123456789";
        
        for( let i=0; i < number; i++ ) {
            text += chars.charAt(Math.floor(Math.random() * chars.length));
        }
    
        return text;
    }
}

export default {
  install(Vue) {
    Vue.prototype.$method;
  }
}   