import router from '@/system/engine/router';
import {getLocalStorage, removeLocalStorage} from '@/system/store/localstorage';

const whiteList = ['/','/nofound', '/maintenance', '/ipay88_receipt'];

router.beforeEach((to, from, next) => {
	if(whiteList.indexOf(to.path) !== -1){
		if(to.path === '/' && getLocalStorage('auth_sign_token')){
			next('/dashboard');
		}else{
			removeLocalStorage('auth_sign_token');
			next();
		}
	}else{
		if(getLocalStorage('auth_sign_token')){
			var permissionList = JSON.parse(getLocalStorage('permissionList'));
			if(permissionList.includes(to.meta.permission)){
				next();
			}else{
				next('/nofound');
			}
		}else{
			next('/');
		}
	}
});
