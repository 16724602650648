import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/css/mdb.min.css'
import Vue from 'vue';
import App from './App.vue';
import router from './system/engine/router';
import store from './system/store';
import Cookies from 'js-cookie';
import Element from 'element-ui';
import i18n from './system/language';
import bootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/css/main.css';
import './assets/css/style.css';
import './assets/css/admin.css';
import './system/engine/permission';
import Global from './system/model/global/method.js'
import 'element-ui/lib/theme-chalk/index.css';
import VueHtmlToPaper from 'vue-html-to-paper';
import VueQr from 'vue-qr';

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
	'https://css.uniwebit.com.my/element-ui.css?ver=7'
  ]
}

Vue.use(VueHtmlToPaper, options);
Vue.use(bootstrapVue);
Vue.use(Global);

Vue.component('vue-qr', VueQr);

Vue.use(Element, {
  size: Cookies.get('size') || 'small',
  i18n: (key, value) => i18n.t(key, value)
})

Vue.config.productionTip = false;

new Vue({
	i18n,
	router,
	store,
	render: h => h(App),
}).$mount('#app')
