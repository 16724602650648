export function genRandomCode(number){
    let text = "";
    let chars = "abcdefghijklmnopqrstuvwxyz123456789";
    
    for( let i=0; i < number; i++ ) {
		text += chars.charAt(Math.floor(Math.random() * chars.length));
    }

	return text.toUpperCase();
}

export function genRandomSerialCode(number){
    let text = "";
    let chars = "0123456789";
    
    for( let i=0; i < number; i++ ) {
		text += chars.charAt(Math.floor(Math.random() * chars.length));
    }

	return text;
}

export function getCode(number, capital){
	let text = "";
    let chars = "abcdefghijklmnopqrstuvwxyz123456789";
	
	text = chars.charAt(number);

	if(capital == 'big'){
		text = text.toUpperCase();
	}
	
	return text;
}

export function getNumber(number){
	let text = "";
    let chars = "0123456789";
	
	text = chars.charAt(number);
	
	return text;
}

export function getSpecial(number){
	let text = "";
    let chars = "~!@#$%^&*()-=+[]{}<>?:;'.";
	
	text = chars.charAt(number);
	
	return text;
}