import axios from 'axios';
import router from '@/system/engine/router';
import {getLocalStorage, removeLocalStorage} from '@/system/store/localstorage';
import {getDAES} from '@/system/library/security';

const service = axios.create({
	baseURL: process.env.BASE_API,
	timeout: 0
});

service.interceptors.request.use(
	config =>{
		const token = getLocalStorage('auth_sign_token');
		
		if(token){
			config.headers['XSPOT'] = token;
		}
		return config;
	}, error =>{
		Promise.reject(error);
	}

);

service.interceptors.response.use(
	response =>{
		const data = response.data;
		
		if(typeof data.maintenance !== 'undefined') {
			router.push('/maintenance');
		}else if(typeof data.error_status !== 'undefined'){
			removeLocalStorage('auth_sign_token');
			router.push('/');
		}else{
			if(location.hostname == "localhost"){
				console.log(JSON.parse(getDAES(data.data)));
			}
			return data;
		}
	}, error =>{
		Promise.reject(error);
	}

);


export default service;