<template>
	<el-scrollbar wrap-class="scrollbar-wrapper" v-if="permissionList">
		<el-menu :default-active="$route.path" :collapse="isCollapse" background-color="#304156" text-color="#bfcbd9" active-text-color="#409EFF" :collapse-transition="true" :unique-opened="true" mode="vertical">
			<sidebar-item v-for="route in routes" :key="route.path" :item="route" :base-path="route.path" />
		</el-menu>
	</el-scrollbar>
</template>

<script>
import {mapGetters} from 'vuex';
import {mainRouter} from '@/system/engine/router';
import SidebarItem from './SidebarItem'
import {getLocalStorage} from '@/system/store/localstorage';

export default {
	components: { SidebarItem },
	data(){
		return {
			routes: mainRouter,
			permissionList:[]
		}
	},computed: {
		...mapGetters([
			'sidebar'
		]),
		isCollapse() {
			return !this.sidebar.opened;
		}
	},methods:{
        hasOneShowingChild(children = [],parent) {
            if (children.length < 1 || parent.path == '/dashboard') {
                return true;
            }else{
                return false;
            }
        }
	}, created: function(){
		this.permissionList = JSON.parse(getLocalStorage('permissionList'));
	}
}
</script>