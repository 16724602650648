<template>
	<el-dropdown trigger="click" @command="switch_language">
		<span class="el-dropdown-link">
			{{$t('admin_general.table_language')}} <i class="el-icon-arrow-down el-icon--right"></i>
		</span>
	
		<el-dropdown-menu slot="dropdown">
			<el-dropdown-item v-for="item of languageList" :key="item.code" :command="item.code">
				{{item.name}}
			</el-dropdown-item>
		</el-dropdown-menu>
	</el-dropdown>
</template>

<script>

import {setLocalStorage, getLocalStorage} from '@/system/store/localstorage';

export default{
	inject:['reload'],
	data(){
		return {
			languageList:[
				{ name: '中文', code: 'cn' },
			]			
		}
	}, methods:{
		switch_language(selectLang){
			setLocalStorage('currentLang', selectLang);
			this.$i18n.locale = selectLang;
			this.currentLang = selectLang;
			this.reload();
		}, initial(){
			if(getLocalStorage('languageList')){
				this.languageList = JSON.parse(getLocalStorage('adminLanguageList'));
			}
		},update(){
			if(getLocalStorage('languageList')){
				this.languageList = JSON.parse(getLocalStorage('adminLanguageList'));
			}
		}
	}, created: function(){
		this.initial();
	}
}
</script>