import {getCode,getNumber} from '@/system/library/config';
// import {getCode,getNumber, getSpecial} from '@/system/library/config';

export function getKey(){
	let key1 = getCode(14,'small');
	let key2 = getCode(1,'big');
	let key3 = getCode(3,'small');
	let key4 = getCode(22,'big');
	let key5 = getCode(9,'big');
	let key6 = getCode(4,'big');
	let key7 = getCode(3,'small');
	let key8 = getNumber(7);
	let key9 = getNumber(1);
	let key10 = getNumber(0);
	let key11 = getCode(0,'big');
	let key12 = getCode(4,'small');
	let key13 = getCode(14,'small');
	let key14 = getCode(24,'small');
	let key15 = getCode(0,'big');
	let key16 = getCode(8,'big');
	
	return key1+key2+key3+key4+key5+key6+key7+key8+key9+key10+key11+key12+key13+key14+key15+key16;
}

export function getIV(){

	let key1 = getCode(25,'small');
	let key2 = getCode(20,'big');
	let key3 = getNumber(6);
	let key4 = getCode(15,'small');
	let key5 = getCode(4,'big');
	let key6 = getCode(13,'big');
	let key7 = getCode(9,'small');
	let key8 = getNumber(7);
	let key9 = getCode(5,'small');
	let key10 = getCode(21,'small');
	let key11 = getCode(7,'small');
	let key12 = getCode(0,'small');
	let key13 = getCode(22,'small');
	let key14 = getCode(14,'big');
	let key15 = getNumber(0);
	let key16 = getNumber(1);
	
	return key1+key2+key3+key4+key5+key6+key7+key8+key9+key10+key11+key12+key13+key14+key15+key16;
}