<template>
	<div class="navbar-custom">
		<hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />
		
		<breadcrumb id="breadcrumb-container" class="breadcrumb-container" />
		
		<div class="right-menu">
			<language id="language-select" style="font-size:16px;cursor: pointer;" class="right-menu-item" />
			
			<el-dropdown class="right-menu-item avatar-container" trigger="click">
				<span class="el-dropdown-link">
					<i class="el-icon-s-tools"></i>
				</span>
  
				<el-dropdown-menu slot="dropdown">
					<!-- <router-link to="/control/profile">
						<el-dropdown-item>{{$t('menu.control_profile')}}</el-dropdown-item>
					</router-link> -->
					
					<el-dropdown-item divided>
						<span style="display:block;" @click="logout">{{$t('button.logout')}}</span>
					</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
	</div>

</template>

<script>
import { mapGetters } from 'vuex';
import language from '@/components/language';
import Hamburger from '@/components/hamburger';
import Breadcrumb from '@/components/breadcrumb';
import {removeLocalStorage} from '@/system/store/localstorage';
import {logout} from '@/system/model/common/logout';
import {getEAES} from '@/system/library/security';

export default {
	components: {
		language,
		Hamburger,
		Breadcrumb
	},computed: {
		...mapGetters([
			'sidebar',
			'device'
		])
	}, data(){
		return {
			permissionList: [],
			permissionButton:{
				profile: '4IOAJK',
				change_password: 'RSNEOB'
			},
			postData: {
				data: '',
				language: '',
			},
			submitData:{
				test:'test'
			}
		}
	}, methods:{
		toggleSideBar() {
			this.$store.dispatch('app/toggleSideBar');
		},async logout(){
			var self = this;
			this.postData.data = getEAES(JSON.stringify(self.submitData));
			var result = logout(self.postData);
			
			result.then(function(){

				removeLocalStorage('auth_sign_token');
				removeLocalStorage('languageList');
				removeLocalStorage('adminLanguageList');
				removeLocalStorage('permissionList');
				self.$router.push('/');
			});
		}
	}, created: function(){
		// this.permissionList = JSON.parse(getLocalStorage('permissionList'));
	}
}
</script>