<template>
	<section class="app-main">
		<transition name="fade-transform" mode="out-in">
			<keep-alive :include="cachedViews">
				<router-view :key="key" @closeCurrentTags="closeCurrentTags"/>
			</keep-alive>
		</transition>
	</section>
</template>

<script>
export default {
	name: 'AppMain',
	computed: {
		cachedViews() {
			return this.$store.state.tagsView.cachedViews;
		},key() {
			return this.$route.path;
		}
	}, methods:{
		closeCurrentTags(){
			this.$emit('closeCurrentTags');
		}
	}
}
</script>
