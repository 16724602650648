<template>
	<div v-show="!item.hidden && permissionList.includes(item.permission)" class="menu-wrapper">
		<template v-if="hasOneShowingChild(item.children,item) && (!onlyOneChild.children||onlyOneChild.noShowingChildren)&&!item.alwaysShow">
			<app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
				<el-menu-item :index="resolvePath(onlyOneChild.path)" :class="{'submenu-title-noDropdown':!isNest}">
					<item :icon="onlyOneChild.meta.icon||(item.meta&&item.meta.icon)" :title="generateTitle(onlyOneChild.meta.title)" />
				</el-menu-item>
			</app-link>
		</template>

		<el-submenu v-else ref="subMenu" :index="resolvePath(item.path)" popper-append-to-body v-show="permissionList.includes(item.permission)">
			<template slot="title">
				<item v-if="item.meta" :icon="item.meta && item.meta.icon" :title="generateTitle(item.meta.title)" />
			</template>
			
			<sidebar-item v-for="child in item.children" :key="child.path" :is-nest="true" :item="child" :base-path="resolvePath(child.path)" class="nest-menu"/>
		</el-submenu>
	</div>
</template>

<script>
import path from 'path';
import { generateTitle } from '@/utils/i18n';
import { isExternal } from '@/utils/validate'
import Item from './Item';
import AppLink from './Link';
import FixiOSBug from './FixiOSBug';
import {getLocalStorage} from '@/system/store/localstorage';

export default {
	name: 'SidebarItem',
	components: { Item, AppLink },
	mixins: [FixiOSBug],
	props: {
		item: {
			type: Object,
			required: true
		},
		isNest: {
			type: Boolean,
			default: false
		},
		basePath: {
			type: String,
			default: ''
		}
	},
	data() {
		this.onlyOneChild = null
		return {
			permissionList:[]
		}
	},
	methods: {
		hasOneShowingChild(children = [], parent) {
			const showingChildren = children.filter(item => {
				if(item.hidden) {
					return false;
				}else{
					this.onlyOneChild = item;
					return true;
				}
			});

			if(showingChildren.length === 1) {
				return true;
			}

			if(showingChildren.length === 0) {
				this.onlyOneChild = { ... parent, path: '', noShowingChildren: true };
				return true;
			}

			return false;
		},
		resolvePath(routePath) {
			if (isExternal(routePath)) {
				return routePath;
			}
			return path.resolve(this.basePath, routePath);
		},
		generateTitle
	}, created: function(){
		this.permissionList = JSON.parse(getLocalStorage('permissionList'));
	}
}
</script>
