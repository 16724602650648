import {setLocalStorage, getLocalStorage} from '@/system/store/localstorage';

const state = {
	sidebar: {
		opened: getLocalStorage('sidebarStatus') ? !!+getLocalStorage('sidebarStatus') : true,
		withoutAnimation: false
	},
	device: 'desktop',
	size: getLocalStorage('size') || 'small'
}

const mutations = {
	TOGGLE_SIDEBAR: state => {
		state.sidebar.opened = !state.sidebar.opened;
		state.sidebar.withoutAnimation = false;
		if(state.sidebar.opened) {
			setLocalStorage('sidebarStatus', 1);
		}else{
			setLocalStorage('sidebarStatus', 0);
		}
	},CLOSE_SIDEBAR: (state, withoutAnimation) => {
		setLocalStorage('sidebarStatus', 0);
		state.sidebar.opened = false;
		state.sidebar.withoutAnimation = withoutAnimation
	},TOGGLE_DEVICE: (state, device) => {
		state.device = device;
	},SET_SIZE: (state, size) => {
		state.size = size;
		setLocalStorage('size', size);
	}
}

const actions = {
	toggleSideBar({ commit }) {
		commit('TOGGLE_SIDEBAR');
	},closeSideBar({ commit }, { withoutAnimation }) {
		commit('CLOSE_SIDEBAR', withoutAnimation);
	},toggleDevice({ commit }, device) {
		commit('TOGGLE_DEVICE', device);
	},setSize({ commit }, size) {
		commit('SET_SIZE', size);
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
