<template>
	<div id="tags-view-container" class="tags-view-container">
		<scroll-pane ref="scrollPane" class="tags-view-wrapper">
			<div class="tags-view-item" @click.prevent.stop="closeAllTags()">
				{{$t('button.close_all')}}
			</div>
			<router-link v-for="tag in visitedViews" ref="tag" :key="tag.path" :class="isActive(tag) ? 'active': ''"  :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath}" tag="span" class="tags-view-item" @click.middle.native="closeSelectedTag(tag)">
				{{$t(tag.title)}}
				<i class="el-icon-refresh tabbar-icon" @click="refreshSelectedTag(tag)"></i>
				<i v-if="tag.title != 'menu.dashboard'" class="el-icon-close tabbar-icon" @click.prevent.stop="closeSelectedTag(tag)"></i>
			</router-link>
		</scroll-pane>
	</div>
</template>

<script>
import ScrollPane from './ScrollPane';
import {mainRouter} from '@/system/engine/router';
import path from 'path';

export default{
	components: { 
		ScrollPane 
	},data() {
		return {
			visible: false,
			top: 0,
			left: 0,
			affixTags: [],
			currentTag: '',
			routes: mainRouter
		}
	},computed: {
		visitedViews() {
			return this.$store.state.tagsView.visitedViews;
		}
	},watch: {
		$route() {
			this.addTags();
			this.moveToCurrentTag();
		}	
	},mounted() {
		this.initTags();
		this.addTags();
	},methods: {
		isActive(route) {
			return route.path === this.$route.path;
		},filterAffixTags(routes, basePath = '/') {
			let tags = [];
			routes.forEach(route => {
				if (route.meta && route.meta.affix) {
					const tagPath = path.resolve(basePath, route.path);
					tags.push({
						fullPath: tagPath,
						path: tagPath,
						name: route.name,
						meta: { ...route.meta }
					});
				}
				if (route.children) {
					const tempTags = this.filterAffixTags(route.children, route.path)
					if (tempTags.length >= 1) {
						tags = [...tags, ...tempTags]
					}
				}
			});
			return tags;
		},initTags() {
			const affixTags = this.affixTags = this.filterAffixTags(this.routes)
			for (const tag of affixTags) {
				if (tag.name) {
					this.$store.dispatch('tagsView/addVisitedView', tag);
				}
			}
		},addTags() {
			const {name} = this.$route;
			
			if(name){
				this.$store.dispatch('tagsView/addView', this.$route);
				this.currentTag = this.$route;
			}
			return false;
		},moveToCurrentTag() {
			const tags = this.$refs.tag;
			this.$nextTick(() => {
				for (const tag of tags) {
					if (tag.to.path === this.$route.path) {
						this.$refs.scrollPane.moveToTarget(tag);

						if (tag.to.fullPath !== this.$route.fullPath) {
							this.$store.dispatch('tagsView/updateVisitedView', this.$route);
						}
						break;
					}
				}
			});
		},refreshSelectedTag(view) {
			this.$store.dispatch('tagsView/delCachedView', view).then(() => {
				const { fullPath } = view;
				this.$nextTick(() => {
					this.$router.replace({
						path: '/redirect' + fullPath
					});
				});
			});
		},closeSelectedTag(view) {
			this.$store.dispatch('tagsView/delView', view).then(({ visitedViews }) => {
				if (this.isActive(view)) {
					this.toLastView(visitedViews, view);
				}
			});
		},closeAllTags() {
			let totalView = this.visitedViews;
			if(totalView.length > 1){
				this.$store.dispatch('tagsView/delAllViews').then(({ visitedViews }) => {
					this.toLastView(visitedViews);
				});
			}
		},closeCurrentTags(){
			this.$store.dispatch('tagsView/delView', this.currentTag).then(({ visitedViews }) => {
				if (this.isActive(this.currentTag)) {
					this.toLastView(visitedViews);
				}
			});
		},toLastView(visitedViews) {
			const latestView = visitedViews.slice(-1)[0]
			if(latestView){
				this.$router.push(latestView);
			}else{
				this.$router.push('/');
			}
		}
	}
}
</script>