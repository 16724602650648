import Vue from 'vue';
import Router from 'vue-router';
import part from '@/views/part';

Vue.use(Router);

export const mainRouter = [
	{
		path: '/redirect',
		component: part,
		permission: 'T6OV7U',
		hidden: true,
		children: [{
			path: '/redirect/:path*',
			component: () => import('@/views/redirect/index'),
			meta: {permission: 'T6OV7U'}
		}]
	},{
		path: '/',
		name: 'Login',
		component: ()=> import('@/views/common/login'),
		hidden: true
	},{
		path: '/dashboard',
		component: part,
		redirect: '/dashboard/index',
		name: 'menu.dashboard',
		permission: 'T6OV7U',
		children: [{
			path: 'index',
			name: 'menu.dashboard_index',
			component: ()=> import('@/views/dashboard/index'),
			meta: { title: 'menu.dashboard', icon: 'house', affix: true, permission: 'T6OV7U'},
			permission: 'T6OV7U'
		}]
	},{
		path: '/gallery',
		component: part,
		redirect: '/gallery/index',
		name: 'menu.gallery',
		permission: 'MPYFXM',
		children: [{
			path: 'index',
			name: 'menu.gallery_index',
			component: ()=> import('@/views/gallery/index'),
			meta: { title: 'menu.gallery', icon: 'picture', noCache: true, permission: 'MPYFXM'},
			permission: 'MPYFXM'
		}]
	},{
		path: '/developer',
		component: part,
		redirect: 'noredirect',
		meta: {title: 'menu.developer', icon: 'smoking', permission: '1IX6MB'},
		permission: '1IX6MB',
		children: [{
			path: 'restore',
			name: 'menu.developer_restore',
			component: ()=> import('@/views/developer/restore'),
			meta: { title: 'menu.developer_restore', noCache: true, permission: 'GGESRB'},
			permission: 'GGESRB'
		},{
			path: 'permission',
			name: 'menu.developer_permission',
			component: ()=> import('@/views/developer/permission'),
			meta: { title: 'menu.developer_permission', noCache: true, permission: 'RCAEQ4'},
			permission: 'RCAEQ4'
		},{
			path: 'password',
			name: 'menu.developer_password',
			component: ()=> import('@/views/developer/password'),
			meta: { title: 'menu.developer_password', noCache: true, permission: 'J564CJ'},
			permission: 'J564CJ'
		}]
	},{
		path: '/front',
		component: part,
		redirect: 'noredirect',
		meta: {title: 'menu.front', icon: 's-platform', permission: '6HP9FN'},
		permission: '6HP9FN',
		children: [{
			path: 'carousel',
			name: 'menu.front_carousel',
			component: ()=> import('@/views/front/carousel'),
			meta: { title: 'menu.front_carousel', noCache: true, permission: 'LYV2X3'},
			permission: 'LYV2X3'
		},{
			path: 'advertisement',
			name: 'menu.front_advertisement',
			component: ()=> import('@/views/front/advertisement'),
			meta: { title: 'menu.front_advertisement', noCache: true, permission: 'Z35IO5'},
			permission: 'Z35IO5'
		},{
			path: 'ecatalog',
			name: 'menu.front_ecatalog',
			component: ()=> import('@/views/front/ecatalog'),
			meta: { title: 'menu.front_ecatalog', noCache: true, permission: 'NLNEI6'},
			permission: 'NLNEI6'
		},{
			path: 'menu',
			name: 'menu.front_menu',
			component: ()=> import('@/views/front/menu'),
			meta: { title: 'menu.front_menu', noCache: true, permission: 'NLNEI6'},
			permission: 'NLNEI6'
		},{
			path: 'footer',
			name: 'menu.front_footer',
			component: ()=> import('@/views/front/footer'),
			meta: { title: 'menu.front_footer', noCache: true, permission: 'NLNEI6'},
			permission: 'NLNEI6'
		},{
			path: 'content',
			name: 'menu.front_content',
			component: ()=> import('@/views/front/content'),
			meta: { title: 'menu.front_content', noCache: true, permission: 'NLNEI6'},
			permission: 'NLNEI6'
		},{
			path: 'content_item',
			name: 'menu.front_content_item',
			component: ()=> import('@/views/front/contentitem'),
			meta: { title: 'menu.front_content_item', noCache: true, permission: 'NLNEI6'},
			permission: 'NLNEI6'
		},{
			path: 'template',
			name: 'menu.front_template',
			component: ()=> import('@/views/front/template'),
			meta: { title: 'menu.front_template', noCache: true, permission: 'NLNEI6'},
			permission: 'NLNEI6'
		},{
			path: 'form',
			name: 'menu.front_form',
			component: ()=> import('@/views/front/form'),
			meta: { title: 'menu.front_form', noCache: true, permission: 'NLNEI6'},
			permission: 'NLNEI6'
		}]
	},{
		path: '/catalog',
		component: part,
		redirect: 'noredirect',
		meta: {title: 'menu.catalog', icon: 'price-tag', permission: 'XEBBKY'},
		permission: 'XEBBKY',
		children: [{
			path: 'brand',
			name: 'menu.catalog_brand',
			component: ()=> import('@/views/catalog/brand'),
			meta: { title: 'menu.catalog_brand', noCache: true, permission: '7FZCKQ'},
			permission: '7FZCKQ'
		},{
			path: 'category',
			name: 'menu.catalog_category',
			component: ()=> import('@/views/catalog/category'),
			meta: { title: 'menu.catalog_category', noCache: true, permission: 'ZQJU2D'},
			permission: 'ZQJU2D'
		},{
			path: 'secondary_category',
			name: 'menu.catalog_secondary_category',
			component: ()=> import('@/views/catalog/secondary_category'),
			meta: { title: 'menu.catalog_secondary_category', noCache: true, permission: 'HPKFE5'},
			permission: 'HPKFE5'
		},{
			path: 'size',
			name: 'menu.catalog_size',
			component: ()=> import('@/views/catalog/size'),
			meta: { title: 'menu.catalog_size', noCache: true, permission: 'J3NMLC'},
			permission: 'J3NMLC'
		},{
			path: 'color',
			name: 'menu.catalog_color',
			component: ()=> import('@/views/catalog/color'),
			meta: { title: 'menu.catalog_color', noCache: true, permission: 'D8ZUFX'},
			permission: 'D8ZUFX'
		},{
			path: 'models',
			name: 'menu.catalog_models',
			component: ()=> import('@/views/catalog/models'),
			meta: { title: 'menu.catalog_models', noCache: true, permission: 'YE9LHV'},
			permission: 'YE9LHV'
		},{
			path: 'product',
			name: 'menu.catalog_product',
			component: ()=> import('@/views/catalog/product/index'),
			meta: { title: 'menu.catalog_product', noCache: true, permission: 'HMHLIS'},
			permission: 'HMHLIS'
		},{
			path: 'product/add',
			name: 'menu.catalog_product_add',
			component: ()=> import('@/views/catalog/product/add'),
			meta: { title: 'menu.catalog_product_add', noCache: true, permission: 'GH2BJ4'},
			permission: 'GH2BJ4',
			hidden: true
		},{
			path: 'product/edit',
			name: 'menu.catalog_product_edit',
			component: ()=> import('@/views/catalog/product/edit'),
			meta: { title: 'menu.catalog_product_edit', noCache: true, permission: '6ELC82'},
			permission: '6ELC82',
			props: true,
			hidden: true
		}]
	},{
		path: '/company',
		component: part,
		redirect: 'noredirect',
		meta: {title: 'menu.company', icon: 'office-building', permission: '3QR6Y2'},
		permission: '3QR6Y2',
		children: [{
			path: 'timeline',
			name: 'menu.company_timeline',
			component: ()=> import('@/views/company/timeline'),
			meta: { title: 'menu.company_timeline', noCache: true, permission: 'RUX3WY'},
			permission: 'RUX3WY'
		},{
			path: 'certifica',
			name: 'menu.company_certifica',
			component: ()=> import('@/views/company/certifica'),
			meta: { title: 'menu.company_certifica', noCache: true, permission: 'H95JDK'},
			permission: 'H95JDK'
		},{
			path: 'progress',
			name: 'menu.company_progress',
			component: ()=> import('@/views/company/progress'),
			meta: { title: 'menu.company_progress', noCache: true, permission: 'KSCUJ5'},
			permission: 'KSCUJ5'
		}]
	},{
		path: '/admin',
		component: part,
		redirect: 'noredirect',
		meta: {title: 'menu.admin', icon: 'user', permission: '8DYC38'},
		permission: '8DYC38',
		children: [{
			path: 'register',
			name: 'menu.admin_register',
			component: ()=> import('@/views/admin/register'),
			meta: { title: 'menu.admin_register', noCache: true, permission: 'VLDLDK'},
			permission: 'VLDLDK'
		},{
			path: 'list',
			name: 'menu.admin_list',
			component: ()=> import('@/views/admin/list'),
			meta: { title: 'menu.admin_list', noCache: true, permission: 'NJEJWJ'},
			permission: 'NJEJWJ'
		}]
	},{
		path: '/network',
		component: part,
		redirect: 'noredirect',
		meta: {title: 'menu.network', icon: 'trophy', permission: 'OC47RL'},
		permission: 'OC47RL',
		children: [{
			path: 'admin',
			name: 'menu.network_admin',
			component: ()=> import('@/views/network/admin/index'),
			meta: { title: 'menu.network_admin', noCache: true, permission: 'UREVUC'},
			permission: 'UREVUC'
		}]
	},{
		path: '/site',
		component: part,
		redirect: 'noredirect',
		meta: {title: 'menu.site', icon: 'bell', permission: 'LMSTIC'},
		permission: 'LMSTIC',
		children: [{
			path: 'news',
			name: 'menu.site_news',
			component: ()=> import('@/views/site/news'),
			meta: { title: 'menu.site_news', noCache: true, permission: 'B25V8O'},
			permission: 'B25V8O'
		},{
			path: 'history',
			name: 'menu.site_news_history',
			component: ()=> import('@/views/site/history'),
			meta: { title: 'menu.site_news_history', noCache: true, permission: '4Y58FG'},
			permission: '4Y58FG'
		}]
	},{
		path: '/report',
		component: part,
		redirect: 'noredirect',
		meta: {title: 'menu.report', icon: 's-order', permission: 'LMSTIC'},
		permission: 'LMSTIC',
		children: [{
			path: 'news',
			name: 'menu.report_email_service',
			component: ()=> import('@/views/report/emailservice'),
			meta: { title: 'menu.report_email_service', noCache: true, permission: 'FQ9EQU'},
			permission: 'FQ9EQU'
		},{
			path: 'history',
			name: 'menu.report_email_oem',
			component: ()=> import('@/views/report/emailoem'),
			meta: { title: 'menu.report_email_oem', noCache: true, permission: 'SV87LU'},
			permission: 'SV87LU'
		}]
	},{
		path: '/tools',
		component: part,
		redirect: 'noredirect',
		meta: {title: 'menu.tools', icon: 'setting', permission: 'L41XX8'},
		permission: 'L41XX8',
		children: [{
			path: 'setting',
			name: 'menu.tools_setting',
			component: ()=> import('@/views/tools/setting'),
			meta: { title: 'menu.tools_setting', noCache: true, permission: '95VN2O'},
			permission: '95VN2O'
		},{
			path: 'systems',
			name: 'menu.tools_systems',
			component: ()=> import('@/views/tools/systems'),
			meta: { title: 'menu.tools_systems', noCache: true, permission: '232KLJ'},
			permission: '232KLJ'
		},{
			path: 'advertisement',
			name: 'menu.tools_advertisement',
			component: ()=> import('@/views/tools/advertisement'),
			meta: { title: 'menu.tools_advertisement', noCache: true, permission: '3O9VMA'},
			permission: '3O9VMA'
		},{
			path: 'permission',
			name: 'menu.tools_permission',
			component: ()=> import('@/views/tools/permission'),
			meta: { title: 'menu.tools_permission', noCache: true, permission: 'HWIGYH'},
			permission: 'HWIGYH'
		},{
			path: 'job',
			name: 'menu.tools_job',
			component: ()=> import('@/views/tools/job'),
			meta: { title: 'menu.tools_job', noCache: true, permission: '7HHKMG'},
			permission: '7HHKMG'
		},{
			path: 'language',
			name: 'menu.tools_language',
			component: ()=> import('@/views/tools/language'),
			meta: { title: 'menu.tools_language', noCache: true, permission: '3JUOLG'},
			permission: '3JUOLG'
		},{
			path: 'email',
			name: 'menu.tools_email',
			component: ()=> import('@/views/tools/email'),
			meta: { title: 'menu.tools_email', noCache: true, permission: 'W8D3X8'},
			permission: 'W8D3X8'
		},{
			path: 'page',
			name: 'menu.tools_page',
			component: ()=> import('@/views/tools/page'),
			meta: { title: 'menu.tools_page', noCache: true, permission: '4F4A4L'},
			permission: '4F4A4L'
		}]
	},{ 
		path: '/nofound', 
		component: () => import('@/views/error/nofound'),
		hidden: true 
	},{ 
		path: '*', 
		redirect: '/nofound',
		hidden: true 
	}
];

const createRouter = () => new Router({
	mode: 'history',
	scrollBehavior: () => ({ y: 0 }),
	routes: mainRouter
});

const router = createRouter();

export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // reset router
}

export default router;