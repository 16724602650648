<template>
	<el-breadcrumb class="app-breadcrumb" separator="/">
		<transition-group name="breadcrumb">
			<el-breadcrumb-item v-for="(item,index) in levelList" :key="item.path">
				<span v-if="item.redirect==='noredirect'||index==levelList.length-1" class="no-redirect">{{
					generateTitle(item.meta.title) }}
				</span>
				<a v-else @click.prevent="handleLink(item)">{{ generateTitle(item.meta.title) }}</a>
			</el-breadcrumb-item>
		</transition-group>
	</el-breadcrumb>
</template>

<script>
import { generateTitle } from '@/utils/i18n';
import pathToRegexp from 'path-to-regexp';

export default {
	data() {
		return {
			levelList: null
		}
	},
	watch: {
		$route() {
			this.getBreadcrumb();
		}
	},
	created() {
		this.getBreadcrumb();
	},
	methods: {
		generateTitle,
		getBreadcrumb() {
			let matched = this.$route.matched.filter(item => item.meta);
			const first = matched[0];

			if (first && first.name !== 'menu.dashboard') {
				matched = [{ path: '/dashboard', meta: { title: this.$t('menu.dashboard') }}].concat(matched);
			}

			this.levelList = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false);
		}, pathCompile(path) {
			const { params } = this.$route;
			var toPath = pathToRegexp.compile(path);
			return toPath(params);
		},handleLink(item) {
			const { redirect, path } = item
			if (redirect) {
				this.$router.push(redirect);
				return;
			}
			this.$router.push(this.pathCompile(path));
		}
	}
}
</script>
