import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
	cn: require('@/system/language/cn.json'),
	en: require('@/system/language/en.json'),
};

const i18n = new VueI18n({
	locale: 'cn',
	messages,
	silentTranslationWarn: true
});

export default i18n;